<template>
  <div style="display: flex; flex-direction: column; height: calc(100vh - 180px)">
    <div style="display: flex; flex-wrap: wrap">
      <div class="condition-item">
        <label class="fn-14">采集任务名称</label>
        <el-input
          v-model="searchForm.taskName"
          placeholder="请输入采集任务名称"
          autocomplete="off"
          style="width: 160px"
          size="small"
        >
        </el-input>
      </div>
      <div class="condition-item">
        <label class="fn-14">开通日期</label>
        <el-date-picker
          v-model="searchForm.stime"
          align="right"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始日期"
          autocomplete="off"
          size="small"
          style="width: 180px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding: 0 10px">-</label>
        <el-date-picker
          v-model="searchForm.etime"
          align="right"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束日期"
          autocomplete="off"
          size="small"
          style="width: 180px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <label class="fn-14">采集日期</label>
        <el-date-picker
          v-model="searchForm.scjtime"
          align="right"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择开始日期"
          autocomplete="off"
          size="small"
          style="width: 180px"
        >
        </el-date-picker>
        <label class="fn-14" style="padding: 0 10px">-</label>
        <el-date-picker
          v-model="searchForm.ecjtime"
          align="right"
          type="date"
          format="yyyy 年 MM 月 dd 日"
          value-format="yyyy-MM-dd"
          placeholder="选择结束日期"
          autocomplete="off"
          size="small"
          style="width: 180px"
        >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-button
          type="primary"
          style="margin-left: 10px"
          size="small"
          @click.native="selectData"
          >查询</el-button
        >
        <el-button type="info" icon="el-icon-refresh" @click="resetSearch" size="small"
          >重置
        </el-button>
      </div>
    </div>
    <div style="margin-bottom: 15px; flex: 1; overflow: hidden">
      <el-table
        :data="dataList"
        border
        style="width: 100%"
        v-loading="loading"
        :header-cell-style="{ background: '#f5f7fa', color: '#606266' }"
      >
        <el-table-column prop="name" label="名称" align="center"></el-table-column>
        <el-table-column
          prop="epayCount"
          label="开通人数（人）"
          align="center"
        ></el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { epayStatistics } from "../../../api/order";

export default {
  name: "BillEpay",
  data() {
    return {
      searchForm: {
        taskName: "", //任务名称
        stime: "", //开始时间
        etime: "", //结束时间
        scjtime: "", //采集开始时间
        ecjtime: "", //采集结束时间
      },
      dataList: [],
      loading: false,
    };
  },
  methods: {
    //搜索
    selectData() {
      this.getEpayStatistics();
    },
    //重置
    resetSearch() {
      this.searchForm = {
        taskName: "", //任务名称
        stime: "", //开始时间
        etime: "", //结束时间
        scjtime: "", //采集开始时间
        ecjtime: "", //采集结束时间
      };
      this.getEpayStatistics();
    },
    /**
     * 查询数据列表
     */
    getEpayStatistics() {
      let search = JSON.parse(JSON.stringify(this.searchForm));
      this.loading = true;
      epayStatistics(search).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          const dataList = [];
          dataList.push({ name: "工行E钱包", epayCount: res.data.epayCount });
          this.dataList = dataList;
        }
      });
    },
  },
  mounted() {
    this.getEpayStatistics();
  },
};
</script>
